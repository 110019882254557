import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import ProtocolInfo from "../ProtocolInfo";
import { correctImage } from "../ProtocolInfo/TestCard";

export const CounterInfo = () => {
  const counter = useSelector((state: RootState) => state.projects?.counter);

  const [isProtocolInfoShowed, setIsProtocolInfoShowed] = useState(false);

  return (
    <>
      {counter ? (
        <div className="flex flex-col gap-y-10 pb-12">
          <p className="text-[#2B7AF1] text-3xl font-extrabold">
            Результат повірки
          </p>
          <div className="flex flex-row gap-x-6 max-sm:flex-col items-center">
            <div className="p-2 bg-gray-200 rounded-lg h-fit">
              <img
                className="rounded-lg p-1"
                width={1000}
                src={`data:image/png;base64,${correctImage(counter?.Image)}`}
              ></img>
            </div>

            <div className="shadow overflow-hidden rounded border-b border-gray-200 w-full">
              <table className="min-w-full h-full bg-white">
                <thead className="bg-[#2B7AF1] text-white">
                  <tr>
                    <th className="w-1/3 text-left py-2 px-4 uppercase font-semibold text-sm">
                      Назва
                    </th>
                    <th className="w-1/3 text-left py-2 px-4 uppercase font-semibold text-sm">
                      Значення
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 font-semibold text-sm">
                      Номер лічильника
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.CounterNumber}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Тип лічильника
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.Type}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Тип
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.serviceType === 0
                        ? "Лічильник холодної води"
                        : "Лічильник гарячої води"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Рік виробництва
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.Year}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Покази на момент повірки
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">-</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Статус лічильника
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.Result === "GUD" ? "Придатний" : "Не придатний"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Номер документу про повірку
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">-</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Дата документу про повірку
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">-</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Дата чинності документу про повірку
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">-</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Дата протоколу повірки
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.Date}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Номер протоколу повірки
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm ">
                      {counter?.FileNumber}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="p-4 gap-x-2 flex flex-row">
                <button
                  onClick={() => setIsProtocolInfoShowed(!isProtocolInfoShowed)}
                  className="py-1 px-6 text-white shadow-md rounded-md bg-gradient-to-r from-[#2B7AF1] to-[#5a9cff]"
                >
                  {isProtocolInfoShowed
                    ? "Сховати протокол"
                    : "Електронний протокол"}
                </button>
                <button className="py-1 px-6 text-white shadow-md rounded-md bg-gradient-to-r from-[#2B7AF1] to-[#5a9cff]">
                  Подивитися документ про повірку
                </button>
              </div>
            </div>
          </div>
          {isProtocolInfoShowed && <ProtocolInfo counter={counter} />}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
