import React from "react";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
  getDataThunk,
  setCounterNumber,
} from "../../store/project/projectsSlice";
import { ServiceCard } from "./components/ServiceCard";

import money from "../../assets/money.svg";
import documents from "../../assets/documents.svg";
import security from "../../assets/security.svg";
import time from "../../assets/time.svg";

import deal from "../../assets/deal.svg";
import review from "../../assets/review.svg";
import home from "../../assets/home.svg";

import location from "../../assets/location.svg";
import phone from "../../assets/phone.svg";

import counterImg from "../../assets/counterImg.png";

import poslugi from "../../assets/poslugi.png";

import { CounterInfo } from "../common/CounterInfo";

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const zvtNumber = useSelector(
    (state: RootState) => state.projects.counterNumber
  );
  const errorMessage = useSelector(
    (state: RootState) => state.projects?.errorMessage
  );
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row justify-between bg-[#2B7AF1]">
        <div className="flex flex-row py-3 pl-12 max-sm:px-8 gap-x-5 items-center">
          <div className="text-[#3c3a37] flex flex-col gap-y-3">
            <div className="pb-4">
              <h2 className="text-[25px] text-left pt-10 max-sm:pt-6 font-bold text-white">
                База документiв
              </h2>
              <h4 className="w-[80%] max-sm:w-full font-medium text-white">
                Будь ласка, введіть номер лічильника, для якого оформлено
                документ
              </h4>
            </div>
            <div className="flex max-sm:w-full flex-row pb-4 w-[80%]">
              <input
                onChange={(e) => dispatch(setCounterNumber(e.target.value))}
                className="w-full border border-[#3c3a372f] py-2 pl-4 rounded-l-full"
                type="text"
                placeholder="Номер лічильника"
              />
              <button
                onClick={() => dispatch(getDataThunk(zvtNumber))}
                className="py-3 px-6 text-white shadow-md rounded-r-full bg-gradient-to-r from-[#FF7A00] to-[#FFB039]"
                aria-label="Пошук лічильника"
              >
                Пошук
              </button>
            </div>
            <div
              className={`text-orange-300 font-bold ${
                !errorMessage && "invisible"
              }`}
            >
              Лічильника з таким номером не існує, зверніться за номером
              телефону
              {/* <br />
              <span className="text-blue-300 font-bold">
                Номер для тесту: 234333
              </span> */}
            </div>
          </div>
        </div>
        <div className="pr-8 w-1/2 py-8 hidden lg:block">
          <img
            className="rounded-l-xl"
            src={counterImg}
            alt="повірка лічильників води Миколаїв"
            loading="lazy"
          />
        </div>
      </div>
      <div className="px-12">
        <CounterInfo />
      </div>
      <div className="px-12 pt-4 pb-4 flex flex-wrap justify-center text-center">
        <div className="flex flex-col gap-y-2">
          <h1 className="text-3xl font-extrabold bg-gradient-to-r from-blue-500 to-sky-500 bg-clip-text text-transparent">
            Повірка лічильників води у Миколаєві та Южноукраїнську
          </h1>
          <h2 className="text-gray-400 text-sm font-semibold">
            За 30 хвилин, без демонтажу (в приміщенні) без порушення пломб. З
            опцією подання результатів повірки до місцевого водоканалу.
          </h2>
        </div>
      </div>
      <div className="px-12 flex flex-row justify-between flex-wrap gap-y-4">
        <ServiceCard
          alt="договір"
          img={documents}
          text="Укладаємо договори, надаємо звітні документи"
        />
        <ServiceCard
          alt="оплата"
          img={money}
          text="Безготівковий розрахунок та оплата карткою на місці"
        />
        <ServiceCard alt="робочі дні" img={time} text="Працюємо по вихідних" />
        <ServiceCard
          alt="звіти"
          img={security}
          text="Надання звітів до місцевого водоканалу"
        />
      </div>
      <div className="px-12 pt-12 pb-12 flex flex-wrap">
        <div className="flex flex-col gap-y-10">
          <p className="text-[#2B7AF1] text-3xl font-extrabold">Про нас</p>
          <div className="flex flex-row max-sm:flex-col gap-x-20 max-sm:gap-y-8 items-center">
            <div className="w-7/12 flex flex-col max-sm:w-full font-medium gap-y-4">
              <p>
                Компанія <strong>ТОВ "МЕТРОЛОГІЧНЕ ЗАБЕЗПЕЧЕННЯ"</strong> понад
                3 років надає професійні метрологічні послуги в т.ч. з{" "}
                <strong>
                  повірки лічильників води в Миколаєві та Южноукраїнську
                </strong>
                . Наша повірочна лабораторія має свідоцтво про уповноваження на
                проведення повірки засобів вимірювальної техніки, що перебувають
                в експлуатації та застосовуються у сфері законодавчо
                регульованої метрології (далі дозвільні документи{" "}
                <strong>№П-91-2022 від 04.01.2022</strong>).
              </p>
              <p>
                Також <strong>Міністерством Економіки України</strong> наші
                відомості про уповноваження внесені до бази даних про наукові
                метрологічні центри, метрологічні центри та повірочні
                лабораторії, уповноважені на проведення повірки засобів
                вимірювальної техніки, що перебувають в експлуатації.
              </p>
            </div>
            <div className="flex flex-col items-center justify-between gap-y-8 w-full lg:flex-row max-sm:gap-y-8 lg:items-end">
              <div className="flex flex-row gap-x-4">
                <img src={deal} alt="клієнти" />
                <div className="flex flex-col gap-y-2 justify-center">
                  <p className="text-[#2B7AF1] text-3xl font-extrabold">500+</p>
                  <p className="font-normal">Клієнтів</p>
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <img src={review} alt="відгуки" />
                <div className="flex flex-col gap-y-2 justify-center">
                  <p className="text-[#2B7AF1] text-3xl font-extrabold">300+</p>
                  <p className="font-normal">Відгуків</p>
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <img src={home} alt="повірки" />
                <div className="flex flex-col gap-y-2 justify-center">
                  <p className="text-[#2B7AF1] text-3xl font-extrabold">600+</p>
                  <p className="font-normal">Повірок</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-12 pt-12 pb-12 flex flex-wrap">
        <div className="flex flex-col gap-y-10">
          <p className="text-[#2B7AF1] text-3xl font-extrabold">
            Наші основні послуги
          </p>
          <div className="flex items-center gap-x-20">
            <div className="flex flex-col gap-x-20 gap-y-8">
              <p className="max-sm:w-full font-medium">
                <strong>
                  Повірка лічильників води без зняття та порушення пломб
                </strong>{" "}
                - це послуга, яку сьогодні активно шукають мешканці міста{" "}
                Миколаїв. Звертаючись до нас, ви заощаджуєте свій час та
                отримуєте високоякісний сервіс. Ми маємо спеціалістів з повірки
                лічильників води, з досвідом роботи більше ніж 5 років. Це
                гарантує <b>якісне надання послуг</b> з повірки. Наші майстри
                виїжджають вже <b>через 1-14 днів</b> після подачі заявки, а на
                повірку одного лічильника води на дому їм потрібно всього{" "}
                <b>30-40 хвилин</b>.
              </p>
              <p className="max-sm:w-full font-medium">
                <b>ТОВ «МЕТРОЛОГІЧНЕ ЗАБЕЗПЕЧЕННЯ»</b> маючи високоякісне
                обладнання виконує повірку лічильників води{" "}
                <strong>без демонтажу</strong> на місці експлуатації приладу. В
                квартирі, в будинку, в підвалі, головне щоб поруч була подача
                води та її відведення. Придатність нашого обладнання
                підтверджена чинним сертифікатом калібрування, що є обов'язковим
                та важливим документом.
              </p>
              <p className="max-sm:w-full font-medium">
                Часто люди шукають в Google «<strong>повірку водоміру</strong>{" "}
                чи
                <strong> водолічильника</strong>», або шукають «
                <strong>метрология</strong>, <strong>водомер</strong>,{" "}
                <strong>Николаев</strong>,{" "}
                <strong>проверка счетчика воды</strong>,{" "}
                <strong>контрольные показания для водоканала</strong>,{" "}
                <strong>свірку лічильника води</strong>,{" "}
                <strong>сверку водомера</strong>,{" "}
                <strong>проверка на дому</strong> і т.і.» та за некоректним
                запитом не можуть знайти необхідну відповідь, або знаходять
                застарілу, неактуальну інформацію. Ми радимо чітко вписувати в
                пошукові системи «
                <strong>повірки засобів вимірювальної техніки</strong> або{" "}
                <strong>повірка лічильників води</strong>» так пошукова система
                надасть більш актуальну інформацію.
              </p>
            </div>
            <div className="hidden lg:block">
              <img className="rounded-l-xl" src={poslugi} alt="послуги" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-12 pt-8 pb-8 flex flex-wrap">
        <div className="flex flex-col gap-y-10">
          <p className="text-[#2B7AF1] text-3xl font-extrabold">Контакти</p>
          <div className="flex flex-row gap-x-20">
            <div className="flex flex-col w-full gap-y-4">
              <div className="flex flex-row gap-x-4 font-semibold items-center">
                <img className="w-6 h-6" src={location} alt="локація" />
                <p>вул. Троїцька, 238/4, Миколаїв</p>
              </div>

              <div className="flex flex-row gap-x-4 font-semibold text-[#2B7AF1] items-center">
                <img className="w-6 h-6" src={phone} alt="телефон" />
                <a href="tel:+380731680048" className="text-inherit underline">
                  +380731680048
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-x-20">
            <div className="flex flex-col w-full gap-y-4">
              <div className="flex flex-row gap-x-4 font-semibold items-center">
                <img className="w-6 h-6" src={location} alt="локація" />
                <p>Южноукраїнськ</p>
              </div>

              <div className="flex flex-row gap-x-4 font-semibold text-[#2B7AF1] items-center">
                <img className="w-6 h-6" src={phone} alt="телефон" />
                <a href="tel:+380933444843" className="text-inherit underline">
                  +380933444843
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
